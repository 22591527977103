import { NextComponentType, NextPageContext } from 'next';
import React, { Component } from 'react';
import { fetchFeatures } from 'actions/features';
import { getCSPPolicy } from 'lib/helpers/csp';
import crypto from 'crypto';
import { Buffer } from 'buffer';

interface PageContext extends NextPageContext {
  reduxStore: any;
}

const withCSPHeader = (App: NextComponentType) =>
  class withCSPHeader extends Component {
    static async getInitialProps(ctx: PageContext) {
      // fetch features if not present
      const { features } = ctx.reduxStore.getState();
      const isFeaturesPresent = features.doneFetching && !features.fetchError;
      if (!isFeaturesPresent) {
        await ctx.reduxStore.dispatch(fetchFeatures());
      }

      const contentSecurityPolicyReportOnlyBluehaven =
        ctx.reduxStore.getState().features.features[
          'content_security_policy_report_only_bluehaven'
        ];

      const userAgent = ctx.req?.headers['user-agent'] || '';

      let nonce = '';
      if (typeof window !== 'undefined' && window.crypto) {
        nonce = Buffer.from(
          window.crypto.getRandomValues(new Uint8Array(127)),
        ).toString('base64');
      } else {
        nonce = Buffer.from(crypto.randomBytes(127)).toString('base64');
      }

      const contentSecurityPolicyBluehaven =
        ctx.reduxStore.getState().features.features[
          'content_security_policy_bluehaven'
        ];

      const contentSecurityPolicy =
        contentSecurityPolicyReportOnlyBluehaven?.enabled ||
        contentSecurityPolicyBluehaven?.enabled
          ? getCSPPolicy({
              nonce,
              userAgent,
              isCSPReportOnly:
                contentSecurityPolicyReportOnlyBluehaven?.enabled,
            })
          : null;

      const cspReportUrl =
        process.env.NODE_ENV === 'development'
          ? null
          : '/platform/csp_reports?app=blue-haven';

      if (contentSecurityPolicyReportOnlyBluehaven?.enabled) {
        ctx?.res?.setHeader(
          'Content-Security-Policy-Report-Only',
          `report-uri ${cspReportUrl};` + contentSecurityPolicy,
        );
      }

      if (contentSecurityPolicyBluehaven?.enabled) {
        ctx?.res?.setHeader('Content-Security-Policy', contentSecurityPolicy);
      }

      ctx?.res?.setHeader('x-nonce', nonce);

      let appProps = {};

      if (typeof App.getInitialProps === 'function') {
        appProps = await App.getInitialProps(ctx);
      }

      return {
        ...appProps,
      };
    }

    render() {
      return <App {...this.props} />;
    }
  };

export default withCSPHeader;
